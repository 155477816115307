html, body {
  margin: 0;
  font-family: 'DM Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  font-size: 16px;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  font-family: 'DM Sans', sans-serif;
}

b {
  font-weight: 700;
}

.no-pointer {
  pointer-events: none !important;
}

body {
  /*background-image: url("https://www.visitmalta.com/wp-content/uploads/2021/10/HomePageHero.jpg");*/
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.board {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#root, .App {
  height: 100%;
}

.board-item-outer {
  width: 25%;
  height: 25%;
  /*perspective: 1000px;*/
}

.board-item-inner {
  perspective: 100vw;
  transform-style: preserve-3d;
}

.board-item {
  background: #fff;
  width: 100%;
  height: 100%;
  cursor: pointer;
  border-radius: 10%;
  border: solid 2px #afafaf;
  position: relative;
  transform-style: preserve-3d;
  transition: .3s ease-in-out;
}

.board-item.retro {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: auto;
  width: auto;
  transform: translateZ(-1px) rotateY(-180deg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.board-inner {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
}

.text {
  position: absolute;
  top: 7%;
  left: 7%;
  right: 7%;
  bottom: 7%;
  font-weight: 700;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3%;
  font-size: 0.9rem;
  border: solid 4px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.red {
  background-color: rgba(203, 48, 52, 1);
  border-color: rgba(203, 48, 52, 1);
}

.yellow {
  background-color: rgba(243, 205, 76, 1);
  border-color: rgba(243, 205, 76, 1);
}

.green {
  background-color: rgba(109, 174, 71, 1);
  border-color: rgba(109, 174, 71, 1);
}

.blue {
  background-color: rgba(69, 134, 196, 1);
  border-color: rgba(69, 134, 196, 1);
}

.text.square {
  border-radius: 10%;
}

.text.round {
  border-radius: 50%;
}

.buttons {
  position: fixed;
}

.board {
  margin: 0 auto;
}

.App {
  /*display: flex;*/
  /*flex-direction: column;*/
  /*justify-content: space-between;*/
}

.logo {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.buttons {
  /*width: 18%;*/
}

.buttons button {
  background-color: #DC102A;
  /*box-shadow: none;*/
  outline: none;
  border: solid 3px #fff;
  padding: 14px;
  border-radius: 7px;
  margin-left: 20px;
  position: relative;
  z-index: 9;
  box-shadow: 5px 5px 0 0 #fff;
}

.win {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
}

.buttons button.active {
  background-color: #fff;
  box-shadow: 5px 5px 0 0 #DC102A;
}

* {
  outline: none !important;
}

.buttons button img {
  width: 40px;
}

.gioca {
  outline: none !important;
  position: fixed;
  left: 50%;
  bottom: 15%;
  background: #F9CB1A;
  font-size: 4vh;
  padding: 1vh 18vw;
  transform: translateX(-50%);
  color: #fff;
  border: solid 5px #fff;
  border-radius: 10px;
  box-shadow: 17px 16px 0 0 #fff;
}

@media all and (orientation: landscape) {
  .buttons {
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
}

@media all and (orientation: portrait) {
  .buttons {
    bottom: 40px;
    right: 40px;
    /*left: 50%;*/
    /*transform: translateX(-50%);*/
  }
}
